const $ = require('jquery');
global.$ = global.jQuery = $;

require('slick-carousel');
require('slick-carousel/slick/slick.css');
//require('slick-carousel/slick/slick-theme.css');

require('/jeia/js/luminous/Luminous.min.js')
require('/jeia/js/drift/Drift.min.js')

require('bootstrap');
